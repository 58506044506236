// import EastIcon from "@mui/icons-material/East";
import { Icon } from "@iconify/react";
// import roadmapdata from "./roadmapdata";
export default function ScoreCard({ data }) {
  return (
    <div className='score-card box'>
      <div className='scContent'>
        <div className='ccRoadmapTitle'>
          <h5>{data.title}</h5>
        </div>
        <h5 className='scoreh5'>
          Career match score: <span className='scoreSpan'> {data.score}%</span>
        </h5>
        <div className='ccList'>
          <ul>
            {/* test {data.ul[1].li} */}
            {data.ul.map((item, idx) => (
              <li key={idx}>{item.li}</li>
            ))}
          </ul>
        </div>
        <div className='ad'>
          <div className='scArrowDiv'>
            <h5 className='scH5'>
              {" "}
              {data.score === 100
                ? "Perfect Fit"
                : data.score > 89
                ? "Exceptional Fit"
                : data.score < 90 && data.score > 79
                ? "Excellent Fit"
                : "Good Fit"}
            </h5>
          </div>
        </div>

        <div className='scContent1'>
          <h6>Top Companies:</h6>
          <div className='ccLogo'>
            {data.logo.map((imgSrc, idx2) =>
              imgSrc.url === "images/companies_logo/1.png" ? (
                <img src={imgSrc.url} alt='' style={{ scale: "1.14" }} />
              ) : imgSrc.url === "images/companies_logo/10.png" ? (
                <img src={imgSrc.url} alt='' style={{ marginTop: "5px" }} />
              ) : imgSrc.url === "images/companies_logo/16.png" ? (
                <img
                  src={imgSrc.url}
                  alt=''
                  style={{ transform: "translateY(6px)" }}
                />
              ) : (
                <img src={imgSrc.url} alt='' />
              )
            )}
          </div>
          <a
            href='https://ib.encodingcareers.com'
            className='cs-center'
            target='_blank'
            rel='noopener noreferrer'
          >
            <div className='score-button glass shine'>
              <h5 style={{color:'black'}}>Explore Roadmap </h5>
              <Icon icon='carbon:arrow-right' />{" "}
              {/* <EastIcon style={{ marginLeft: "5px", color: "white" }} /> */}
            </div>
          </a>
        </div>
      </div>
    </div>
  );
}
