import "./intro.css";
import { Icon } from "@iconify/react";
import Countdown from "react-countdown";

export default function IntroModule({ setOpenIntro }) {
  // Calculate the time until the next midnight
  const now = new Date();
  const nextMidnight = new Date();
  nextMidnight.setHours(24, 0, 0, 0); // Set to midnight

  const timeUntilMidnight = nextMidnight - now;
  const renderer = ({ hours, minutes, seconds }) => {
    return (
      <span>
        {hours}h: {minutes}m: {seconds}s
      </span>
    );
  };
  return (
    <div className='introModule'>
      <div className='introTitle'>
        {/* <Icon icon='game-icons:spell-book' className='introIcon' /> */}

        <h6 className='blinkText'>Finance Career Assesment</h6>
      </div>

      <div className='ccArrowDiv'>
        <Icon icon='emojione-v1:flag-for-india' className='indiaIcon' />
        <h5 className='ccH5'>India's First Algo Assessment</h5>
      </div>

      <div className='introCard'>
        <div className='dTitle'>
          <h5 className='dText'>Don't leave </h5>
          {/* <span className='dSpan'> Sucessful Career</span> */}
          <br />
          <h5 className='dText'>without a TRY!!</h5>{" "}
          {/* <span className='dSpan'>Encoding Careers</span> */}
        </div>
        <div className='dLeftDetails'>
          {" "}
          <img src='/images/intro.png' alt='Avatar' className='introImg' />
        </div>
      </div>
      <div
        className='button glass bmButton shine'
        style={{ margin: "0px 20px" }}
        onClick={() => setOpenIntro(false)}
      >
        <div className='introTimer'>
          <h3>
            Start Now for <s style={{ color: "red" }}>₹199</s> FREE
          </h3>
          {/* <h3>
            Free for next:{" "}
            <Countdown
              date={Date.now() + timeUntilMidnight}
              renderer={renderer}
            />
          </h3> */}
        </div>
        <Icon
          icon='bi:arrow-right'
          style={{ scale: "2", marginLeft: "15px", color: "white" }}
        />
      </div>
    </div>
  );
}
